import { defineStore } from 'pinia'
import { User } from '@/types/user'
import { Customer } from '@/types/customer'

export type UserState = {
  current: User,
  customer: Customer
}

export const useUserStore = defineStore({
  id: 'user',
  state: () => ({
    current: null
  }) as UserState,
  actions: {
    setCurrent(login: string, auth_token: string, refresh_token: string) {
      this.current = {
        login: login,
        auth_token: auth_token,
        refresh_token: refresh_token
      }
    },
    setCustomer(customer: Customer) {
      this.customer = customer
    }
  }
})
