import { createRouter, createWebHistory } from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router'
import { useUserStore } from '@/stores/user'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    component: () => import('@/views/LoginPage.vue'),
    meta: { requiresAuthentication: false }
  },
  {
    path: '/main',
    component: () => import('@/views/MainPage.vue'),
    children: [
      {
        path: '',
        redirect: '/main/orders'
      },
      {
        path: 'orders',
        component: () => import('@/views/OrdersPage.vue'),
        meta: { requiresAuthentication: true }
      },
      {
        path: 'orders/:id',
        redirect: to => {
          return `orders/${to.params.id}/status`
        }
      },
      {
        path: 'orders/:id/status',
        component: () => import('@/views/order/StatusPage.vue'),
        meta: { requiresAuthentication: true }
      },
      {
        path: 'orders/:id/blueprints',
        component: () => import('@/views/order/BlueprintListPage.vue'),
        meta: { requiresAuthentication: true }
      },
      {
        path: 'orders/:id/blueprints/:blueprint_id',
        component: () => import('@/views/order/BlueprintPage.vue'),
        meta: { requiresAuthentication: true }
      },
      {
        path: 'orders/:id/articles',
        component: () => import('@/views/order/ArticleListPage.vue'),
        meta: { requiresAuthentication: true }
      },
      {
        path: 'orders/:id/offers',
        component: () => import('@/views/order/OfferListPage.vue'),
        meta: { requiresAuthentication: true }
      },
      {
        path: 'orders/:id/offers/new',
        component: () => import('@/views/order/NewOfferPage.vue'),
        meta: { requiresAuthentication: true }
      },
      {
        path: 'orders/:id/offers/:offer_id',
        component: () => import('@/views/order/OfferPage.vue'),
        meta: { requiresAuthentication: true }
      },
      {
        path: 'orders/:id/offerConfirmations',
        component: () => import('@/views/order/OfferConfirmationListPage.vue'),
        meta: { requiresAuthentication: true }
      },
      {
        path: 'orders/:id/offerConfirmations/:offer_confirmation_id',
        component: () => import('@/views/order/OfferConfirmationPage.vue'),
        meta: { requiresAuthentication: true }
      },
      {
        path: 'orders/:id/call-off',
        component: () => import('@/views/order/CallOffPage.vue'),
        meta: { requiresAuthentication: true }
      },
      {
        path: 'orders/:id/maintenance',
        component: () => import('@/views/order/MaintenancePage.vue'),
        meta: { requiresAuthentication: true }
      },
      {
        path: 'orders/:id/solutions',
        component: () => import('@/views/order/faultreport/SolutionCategoriesPage.vue'),
        meta: { requiresAuthentication: true }
      },
      {
        path: 'orders/:id/solutions/:categoryId',
        component: () => import('@/views/order/faultreport/SolutionsPage.vue'),
        meta: { requiresAuthentication: true }
      },
      {
        path: 'orders/:id/solutions/report',
        component: () => import('@/views/order/faultreport/FaulReportPage.vue'),
        meta: { requiresAuthentication: true }
      },
      {
        path: 'orders/:id/contact',
        component: () => import('@/views/order/ContactPage.vue'),
        meta: { requiresAuthentication: true }
      },
      {
        path: 'info',
        component: () => import('@/views/other/AboutPage.vue'),
        meta: { requiresAuthentication: true }
      },
      {
        path: 'manuals',
        component: () => import('@/views/other/ManualCategoriesPage.vue'),
        meta: { requiresAuthentication: true }
      },
      {
        path: 'manuals/:categoryId',
        component: () => import('@/views/other/ManualsPage.vue'),
        meta: { requiresAuthentication: true }
      },
      {
        path: 'imprint',
        component: () => import('@/views/other/ImprintPage.vue'),
        meta: { requiresAuthentication: true }
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to) => {
  if (to.meta.requiresAuthentication && useUserStore().current === null) {
      return '/login'
  }
})

export default router
